.otp-verifier {
    display: flex;
    position: relative;
    flex-direction: column;

    .otp-button {
        -webkit-align-self: center;
        align-self: center;
        height: 33px;
        border: 1px solid #efefef;
        background: #efefef;
        position: absolute;
        top: 71px;

        >i {
            font-weight: bold;
        }
    }
}

body[dir='rtl'] .otp-verifier {
    .otp-button {
        right: 235px;
    }
}

body[dir='ltr'] .otp-verifier {
    .otp-button {
        left: 235px;
    }
}
